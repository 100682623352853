import React from 'react';
import { SVGProps } from 'react';

export const SvgOk = (props: SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16Z"
            fill="#BBC1C7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.76 12.129c0-.943-.794-1.71-1.77-1.71-.977 0-1.772.767-1.772 1.71 0 .942.795 1.71 1.771 1.71.977 0 1.771-.768 1.771-1.71Zm2.506 0c0 2.276-1.917 4.128-4.277 4.128-2.359 0-4.277-1.852-4.277-4.128C11.712 9.852 13.63 8 15.989 8c2.36 0 4.277 1.852 4.277 4.13Zm-.052 6.505a8.176 8.176 0 0 1-2.483.993l2.39 2.308a1.18 1.18 0 0 1 0 1.71 1.284 1.284 0 0 1-1.77 0L16 21.378l-2.35 2.269a1.275 1.275 0 0 1-.886.354c-.32 0-.641-.118-.886-.354a1.18 1.18 0 0 1 0-1.71l2.39-2.31a8.189 8.189 0 0 1-2.483-.992 1.185 1.185 0 0 1-.392-1.668 1.278 1.278 0 0 1 1.727-.38 5.596 5.596 0 0 0 5.76 0 1.279 1.279 0 0 1 1.727.38 1.185 1.185 0 0 1-.393 1.668Z"
            fill="#fff"
        />
    </svg>
);
