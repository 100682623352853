import React from 'react';
import { SVGProps } from 'react';

export const SvgVk = (props: SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16Z"
            fill="#BBC1C7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.345 21.867s.316-.037.478-.216c.149-.165.144-.476.144-.476s-.02-1.45.633-1.664c.644-.21 1.47 1.402 2.348 2.023.662.469 1.165.366 1.165.366l2.344-.033s1.226-.078.645-1.072c-.048-.082-.34-.736-1.743-2.079-1.47-1.406-1.272-1.179.497-3.612 1.078-1.481 1.51-2.385 1.374-2.772-.127-.37-.92-.272-.92-.272l-2.639.017s-.195-.027-.34.062c-.142.088-.234.292-.234.292s-.417 1.146-.974 2.121c-1.175 2.057-1.645 2.166-1.837 2.038-.447-.298-.335-1.196-.335-1.834 0-1.994.293-2.825-.571-3.04-.287-.072-.498-.119-1.232-.127-.941-.01-1.738.004-2.19.231-.3.152-.532.49-.39.51.174.023.57.109.779.403.27.38.26 1.23.26 1.23s.156 2.348-.363 2.639c-.355.2-.842-.208-1.89-2.075-.537-.955-.942-2.012-.942-2.012s-.078-.197-.218-.304a1.06 1.06 0 0 0-.405-.168l-2.507.017s-.377.01-.515.18c-.123.15-.01.46-.01.46s1.963 4.736 4.186 7.122c2.039 2.188 4.353 2.045 4.353 2.045h1.049Z"
            fill="#fff"
        />
    </svg>
);
