import React, { FC } from 'react';
import * as Styled from './footer-app-link.styles';

interface FooterAppLinkProps {
    content: {
        title: JSX.Element;
        description: JSX.Element;
        appLink: string;
        appIcon: JSX.Element;
    };
    index: number;
    onClick: () => void;
}

export const FooterAppLink: FC<FooterAppLinkProps> = ({ content, index, onClick }) => {
    return (
        <Styled.AppLinkWrap data-testid={`app-link-${index}`} onClick={onClick}>
            <Styled.AppLink href={content.appLink} target="_blank" rel="noreferrer noopener">
                <Styled.Icon>{content.appIcon}</Styled.Icon>
                <Styled.Text>
                    {content.title}
                    <br />
                    <Styled.About>{content.description}</Styled.About>
                </Styled.Text>
            </Styled.AppLink>
        </Styled.AppLinkWrap>
    );
};
