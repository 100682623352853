import { WebAnalyticsEventActionEnum } from '~/src/models/enums/web-analytics.enum';
import { FooterEventActionEnum } from './footer.types';

export const getEventAction = (eventAction: FooterEventActionEnum) => {
    switch (eventAction) {
        case FooterEventActionEnum.element: {
            return WebAnalyticsEventActionEnum.elementClick;
        }
        case FooterEventActionEnum.socialIcon: {
            return WebAnalyticsEventActionEnum.socialClick;
        }
        case FooterEventActionEnum.appIcon: {
            return WebAnalyticsEventActionEnum.appClick;
        }
        case FooterEventActionEnum.link: {
            return WebAnalyticsEventActionEnum.linkClick;
        }
    }
};
