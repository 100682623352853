import React, { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { openWindow } from '~/src/helpers/open-window.helper';
import { selectIsConnectedCurrentOrganizationInHeader, selectOrganizations } from '~/src/redux/user/user.selectors';
import { selectIsAuth } from '~/src/redux/id-token/id-token.selectors';
import { footerContent } from '~/src/components/sections/footer/footer.constants';
import { FooterAppLink } from '~/src/components/common-components/footer-app-link/footer-app-link';
import { getNavItems } from '~/src/helpers/nav-items.helper';
import { isContainsStatusConnectedOrganisations } from '~/src/helpers/contains-status-connected-organisations';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import { NavItemsEnum } from '~/src/models/enums/nav-items.enum';
import { sendFooterClickAnalytics } from './footer.analytics';
import { FooterEventActionEnum } from './footer.types';
import * as Styled from './footer.styles';

const Footer: FC = () => {
    const [date, setDate] = useState<number>(0);
    const isConnectedCurrentOrganizationInHeader = useSelector(selectIsConnectedCurrentOrganizationInHeader);
    const isAuth = useSelector(selectIsAuth);
    const organizations = useSelector(selectOrganizations);
    const isNeedToAddBecomeAgent = !isAuth || !isContainsStatusConnectedOrganisations(organizations);
    const isNeedToInviteAgent = isAuth && isContainsStatusConnectedOrganisations(organizations);

    useEffect(() => {
        setDate(new Date().getFullYear());
    }, []);

    const onClickLink = (url: string, title: string) => {
        sendFooterClickAnalytics(title, FooterEventActionEnum.socialIcon);
        openWindow(url);
    };

    return (
        <Styled.Footer data-testid={'footer'}>
            <ContentContainer>
                <Styled.FooterLayout>
                    <Styled.FooterTitleBlock>
                        <Styled.FooterTitle>
                            {footerContent.blockInfo.title}
                            <Styled.Beta> βeta</Styled.Beta>
                        </Styled.FooterTitle>
                        <Styled.FooterSubtitle>{footerContent.blockInfo.text}</Styled.FooterSubtitle>
                    </Styled.FooterTitleBlock>
                    <Styled.Menu>
                        {getNavItems(
                            isConnectedCurrentOrganizationInHeader
                                ? NavItemsEnum.needToAddClientList
                                : NavItemsEnum.notShow,
                            isNeedToAddBecomeAgent ? NavItemsEnum.needToAddBecomeAgent : NavItemsEnum.notShow,
                            isNeedToInviteAgent ? NavItemsEnum.needToAddInviteAgent : NavItemsEnum.notShow,
                        ).map((item, index) => (
                            <Styled.MenuItem data-testid={`menu-item-${index}`} key={item.id}>
                                <Link
                                    href={item.href}
                                    target={item.targetBlank ? '_blank' : '_self'}
                                    onClick={() => sendFooterClickAnalytics(item.label, FooterEventActionEnum.element)}
                                >
                                    {item.label}
                                </Link>
                            </Styled.MenuItem>
                        ))}
                    </Styled.Menu>
                    <Styled.SocialIcons>
                        {footerContent.socials.map((item, index) => (
                            <Styled.Link
                                role="link"
                                aria-label="social-icon"
                                key={item.id}
                                color={item.color}
                                data-testid={`social-icon-${index}`}
                                onClick={() => onClickLink(item.link, item.title)}
                            >
                                {item.component}
                            </Styled.Link>
                        ))}
                    </Styled.SocialIcons>
                    <Styled.Apps>
                        {footerContent.blockApps.map((item, index) => (
                            <FooterAppLink
                                key={item.id}
                                content={item}
                                index={index}
                                onClick={() =>
                                    sendFooterClickAnalytics(item.titleWithoutHtml, FooterEventActionEnum.appIcon)
                                }
                            />
                        ))}
                    </Styled.Apps>
                    <Styled.DocLinks>
                        {footerContent.blockLinks.map((item, index) => (
                            <Styled.DocLink
                                key={item.id}
                                href={item.link}
                                target="_blank"
                                rel="noreferrer noopener"
                                data-testid={`doc-link-${index}`}
                                onClick={() =>
                                    sendFooterClickAnalytics(item.title, FooterEventActionEnum.link)
                                }
                            >
                                {item.title}
                            </Styled.DocLink>
                        ))}
                    </Styled.DocLinks>
                    <Styled.Copyright>
                        <span>© {date} ПАО «МТС». Все права защищены.</span>
                        <span>18+</span>
                    </Styled.Copyright>
                </Styled.FooterLayout>
            </ContentContainer>
        </Styled.Footer>
    );
};

export default Footer;
