import styled, { keyframes, css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { MAX_1023, MAX_767, MIN_1280, MIN_768 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';
import { AppLinkWrap } from '~/src/components/common-components/footer-app-link/footer-app-link.styles';

export const Footer = styled.footer`
    display: flex;
    justify-content: center;
    background-color: ${COLORS.backgroundLightSecondary};
    color: ${COLORS.textTertiary};
    ${FONTS.sans.medium.size14};
    padding: ${rem(24)} 0;
    width: 100%;
    position: relative;
    box-shadow: 0 4px ${rem(16)} 0 rgba(0, 0, 0, .08), 0 0 6px 0 rgba(0, 0, 0, .08);
;

    ${mediaQuery(
        { minWidth: MIN_768, maxWidth: MAX_1023 },
        css`
            min-width: unset;
        `,
    )}

    ${mediaQuery({ maxWidth: MAX_767 }, css`
        min-width: ${rem(320)};
    `)};
`;

export const FooterLayout = styled.div`
    display: grid;
    box-sizing: border-box;
    grid-gap: ${rem(48)};
    grid-template-columns: 5fr 7fr 10fr;
    grid-template-rows: auto auto;

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            display: flex;
            flex-direction: column;
            grid-gap: ${rem(40)};
        `,
    )};
`;

export const FooterTitleBlock = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;
    color: ${COLORS.textLightPrimary};

    ${mediaQuery({ minWidth: MIN_768, maxWidth: MAX_1023 }, css`
        grid-column-start: 1;
        grid-column-end: 2;
    `)};
`;

export const FooterTitle = styled.div`
    ${FONTS.sans.medium.size17};
`;

export const FooterSubtitle = styled.div`
    width: 100%;
    margin-top: ${rem(8)};
`;

export const Beta = styled.span`
    color: ${COLORS.iconsSecondary};
`;

export const Menu = styled.div`
    display: grid;
    align-self: start;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: ${rem(16)};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr;
        `,
    )};

    ${mediaQuery({ maxWidth: MAX_767 }, css`
        display: flex;
        flex-direction: column;
    `)};
`;

export const MenuItem = styled.div`
    transition: color 250ms;

    a {
        color: ${COLORS.textTertiary};
        text-decoration: none;
    }

    &:hover {
        a {
            color: ${COLORS.controlsPrimaryActive};
        }
    }
`;

export const SocialIcons = styled.div`
    display: flex;

    a {
        margin: 0 ${rem(16)} ${rem(16)} 0;

        ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
                margin-bottom: 0;
            `,
    )};
    }
`;

export const Apps = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${AppLinkWrap} {
        margin-bottom: ${rem(16)};

        ${mediaQuery({ maxWidth: MAX_767 }, css`
            &:last-child {
                margin-bottom: 0;
            }
        `)};
    }

    ${mediaQuery(
        { minWidth: MIN_1280 },
        css`
            flex-direction: row;
        `,
    )};

    ${mediaQuery({ minWidth: MIN_768, maxWidth: MAX_1023 }, css`
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
    `)};
`;

export const DocLinks = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-self: start;
    row-gap: ${rem(16)};

    ${mediaQuery({ minWidth: MIN_768, maxWidth: MAX_1023 }, css`
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 2;
    `)};
`;

export const DocLink = styled.a`
    display: inline-block;
    margin-right: ${rem(24)};
    color: ${COLORS.textTertiary};
    transition: color 250ms;
    text-decoration: none;

    &:hover {
        color: ${COLORS.controlsPrimaryActive};
    }
`;

export const Copyright = styled.div`
    display: flex;
    justify-content: space-between;
    grid-column-start: 1;
    grid-column-end: 4;

    ${mediaQuery({ minWidth: MIN_768, maxWidth: MAX_1023 }, css`
        grid-column-start: 1;
        grid-column-end: 3;
    `)};
`;

const filling = (color: string) => keyframes`
    100% {
        fill: ${color};
    }
`;

const reverseFill = (color: string) => keyframes`
    0% {
        fill: ${color};
    }
    100% {
        fill: ${COLORS.iconsTertiary};
    }
`;

interface Props {
    color: string;
}

export const Link = styled.a<Props>`
    height: fit-content;
    cursor: pointer;

    svg {
        *:first-child {
            animation: ${(p) => reverseFill(p.color)} 250ms;
        }
    }

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            svg {
                height: ${rem(32)};
                width: ${rem(32)};
            }
        `,
    )};

    &:hover {
        svg {
            *:first-child {
                animation: ${(p) => filling(p.color)} 250ms forwards;
            }
        }
    }
`;
