import React from 'react';
import { SVGProps } from 'react';

export const SvgMyMts = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="a"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={32}
            height={32}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.915 0h12.173c3.448 0 4.696.36 5.957 1.032a7.035 7.035 0 0 1 2.923 2.923C31.643 5.216 32 6.465 32 9.913v12.174c0 3.448-.36 4.697-1.032 5.958a7.035 7.035 0 0 1-2.923 2.923C26.784 31.643 25.536 32 22.088 32H9.915c-3.448 0-4.696-.36-5.958-1.032a7.035 7.035 0 0 1-2.922-2.923C.36 26.786 0 25.535 0 22.087V9.915C0 6.467.36 5.22 1.032 3.958a7.032 7.032 0 0 1 2.925-2.926C5.216.36 6.467 0 9.915 0Z"
                fill="#000"
            />
        </mask>
        <g mask="url(#a)">
            <path fill="#BBC1C7" d="M0 0h32v32H0z" />
            <path
                d="M20.536 4.43v1.282h2.748v5.094h1.538V5.712h2.747V4.43h-7.033ZM10.643 4.45l-2.01 4.19-2.009-4.19H4.431v6.336h1.538V6.251l1.964 3.831h1.402l1.964-3.83v4.534h1.538V4.45h-2.194ZM26.027 25.57a1.054 1.054 0 0 1-.3.617 1.284 1.284 0 0 1-.616.312 4.845 4.845 0 0 1-1.018.093 3.323 3.323 0 0 1-1.073-.158 1.338 1.338 0 0 1-.696-.527c-.167-.25-.25-.61-.25-1.067v-.65c0-.46.084-.82.25-1.07.167-.248.411-.434.695-.527.346-.11.709-.164 1.072-.157a4.84 4.84 0 0 1 1.018.092c.23.046.442.154.615.312.167.166.273.384.302.617h1.539a2.252 2.252 0 0 0-.527-1.324 2.408 2.408 0 0 0-1.157-.709 6.486 6.486 0 0 0-1.788-.215c-.738 0-1.377.107-1.901.317a2.43 2.43 0 0 0-1.23 1.056c-.271.468-.414 1.092-.423 1.858v.07l.082.003h-.082v.07c.01.766.152 1.39.423 1.858a2.43 2.43 0 0 0 1.23 1.056c.524.21 1.164.317 1.9.317a6.462 6.462 0 0 0 1.79-.215c.445-.12.846-.366 1.156-.709.314-.372.5-.837.527-1.324l-1.538.004Z"
                fill="#fff"
            />
        </g>
    </svg>
);
