import { GtmService } from '~/src/services/web-analytics.service';
import { getAnalyticsEventData } from '~/src/helpers/web-analytics-events-data.helper';
import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventEnum,
} from '~/src/models/enums/web-analytics.enum';
import { transliterate } from '~/src/helpers/transliterate.helper';
import { FooterEventActionEnum } from './footer.types';
import { getEventAction } from './footer.utils';

export const sendFooterClickAnalytics = (label: string, eventAction: FooterEventActionEnum) => {
    const labelTranslate = transliterate(label);

    GtmService.send(
        getAnalyticsEventData({
            eventAction: getEventAction(eventAction),
            eventLabel: labelTranslate,
            event: WebAnalyticsEventEnum.vntMain,
            eventCategory: WebAnalyticsEventCategoryEnum.footer,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};