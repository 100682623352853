import styled from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { COLORS } from '~/src/styles/tokens/colors';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';

export const About = styled.span`
    transition: color 250ms;
`;

export const AppLink = styled.a`
    display: flex;
    justify-content: flex-start;
    color: ${COLORS.textTertiary};
    align-items: center;
    text-decoration: none;

    svg {
        *:first-child {
            animation: unfilling 250ms;
        }
    }

    :hover {
        svg {
            *:first-child {
                animation: filling 250ms forwards;
            }
        }

        ${About} {
            color: ${COLORS.controlsPrimaryActive};
        }
    }
`;

export const AppLinkWrap = styled.div`
    margin-right: ${rem(16)};

    @keyframes filling {
        100% {
            fill: ${COLORS.controlsPrimaryActive};
        }
    }

    @keyframes unfilling {
        0% {
            fill: ${COLORS.controlsPrimaryActive};
        }
        100% {
            fill: ${COLORS.iconsTertiary};
        }
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${rem(16)};
`;

export const Icon = styled.div`
    width: ${rem(32)};
    height: ${rem(32)};
    border-radius: ${BORDER_RADIUS.normal8};
    overflow: hidden;

    svg {
        width: 100%;
        height: 100%;
    }
`;
