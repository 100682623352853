import React from 'react';
import { SVGProps } from 'react';

export const SvgCashback = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="a"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={32}
            height={32}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.915 0h12.173c3.448 0 4.696.36 5.957 1.032a7.035 7.035 0 0 1 2.923 2.923C31.643 5.216 32 6.465 32 9.913v12.174c0 3.448-.36 4.697-1.032 5.958a7.035 7.035 0 0 1-2.923 2.923C26.784 31.643 25.536 32 22.088 32H9.915c-3.448 0-4.696-.36-5.958-1.032a7.035 7.035 0 0 1-2.922-2.923C.36 26.786 0 25.535 0 22.087V9.915C0 6.467.36 5.22 1.032 3.958a7.032 7.032 0 0 1 2.925-2.926C5.216.36 6.467 0 9.915 0Z"
                fill="#000"
            />
        </mask>
        <g mask="url(#a)">
            <path fill="#BBC1C7" d="M0 0h32v32H0z" />
            <path
                d="M13.046 7.385h5.908L12.308 16l6.646 8.615h-5.908l-4.945-6.488a3.508 3.508 0 0 1 0-4.254l4.945-6.488Z"
                fill="#fff"
            />
            <path d="M16 13.539h7.877v4.923H16v-4.923Z" fill="#fff" />
        </g>
    </svg>
);
