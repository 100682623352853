import React from 'react';
import { nonMtsColors } from '~/src/styles/variables';
import { SvgOk } from '~/src/components/svg-components/ok';
import { SvgVk } from '~/src/components/svg-components/vk';
import { SvgMyMts } from '~/src/components/svg-components/my-mts';
import { SvgCashback } from '~/src/components/svg-components/cashback';

export const footerContent = {
    blockInfo: {
        title: 'МТС Агенты',
        text: (
            <>
                Наши партнеры зарабатывают больше.
                <br />
                Станьте одним из&nbsp;партнёров МТС и&nbsp;
                увеличивайте количество клиентов вместе
                &nbsp;с&nbsp;нами.
            </>
        ),
    },
    socials: [
        {
            component: <SvgOk key="ok" />,
            link: 'https://ok.ru/mts',
            color: nonMtsColors.ok,
            title: 'ok',
            id: 1,
        },
        {
            component: <SvgVk key="vk" />,
            link: 'https://vk.com/mts',
            color: nonMtsColors.vk,
            title: 'vk',
            id: 2,
        },
    ],
    blockApps: [
        {
            title: <>Мой&nbsp;МТС</>,
            titleWithoutHtml: 'moi_mts',
            id: 0,
            description: <>О&nbsp;приложении</>,
            appLink: 'http://mts-service.mts.ru/?utm_source=agents&utm_medium=mts',
            appIcon: <SvgMyMts key="my-mts" />,
        },
        {
            title: <>МТС&nbsp;Cashback</>,
            titleWithoutHtml: 'mts_cashback',
            id: 1,
            description: <>О&nbsp;приложении</>,
            appLink: 'https://cashback.mts.ru/?utm_source=agents&utm_medium=mts',
            appIcon: <SvgCashback key="cashback" />,
        },
    ],
    blockLinks: [
        {
            title: 'Контакты',
            id: 0,
            link: 'https://moskva.mts.ru/personal/kontaktu',
        },
        {
            title: 'Раскрытие информации',
            id: 1,
            link: 'https://moskva.mts.ru/about/investoram-i-akcioneram/korporativnoe-upravlenie/raskritie-informacii',
        },
        {
            title: 'Документы ПАО «МТС»',
            id: 2,
            link: 'https://moskva.mts.ru/about/investoram-i-akcioneram/korporativnoe-upravlenie/dokumenti-pao-mts',
        },
        {
            title: 'Политика обработки cookies',
            id: 3,
            link: 'https://moskva.mts.ru/personal/cookie',
        },
        {
            title: 'Правовая информация',
            id: 4,
            link: 'https://profile.mts.ru/documents/contracts/offers',
        },
        {
            title: 'Новости',
            id: 5,
            link: 'https://moskva.mts.ru/personal/novosti',
        },
        {
            title: 'Комплаенс и деловая этика',
            id: 6,
            link: 'https://moskva.mts.ru/about/komplaens-i-delovaya-etika',
        },
        {
            title: 'Пользовательское соглашение',
            id: 7,
            link: 'https://account.b2b.mts.ru/documents/contracts/offers',
        },
    ],
};
