import { SER_CON_AGENTS_URL } from '~/src/params';
import { NavItemsEnum } from '~/src/models/enums/nav-items.enum';
import type { TargetType } from '~/src/models/types/target.types';

export interface NavItem {
    id: number;
    href: string;
    label: string;
    navigate?: boolean;
    targetBlank?: boolean;
    target?: TargetType;
    needToAddConditions: NavItemsEnum;
    isShow: boolean;
    children?: NavItem[];
}

let globalId = 0;

const NAV_ITEMS: NavItem[] = [
    {
        href: SER_CON_AGENTS_URL,
        label: 'Подписать оферту',
        id: globalId++,
        navigate: true,
        needToAddConditions: NavItemsEnum.needToAddBecomeAgent,
        isShow: false,
    },
    {
        href: '/fizlicam',
        label: 'Для физлиц',
        id: globalId++,
        navigate: true,
        needToAddConditions: NavItemsEnum.needToAddBecomeAgent,
        isShow: false,
    },
    {
        href: '#',
        label: 'Список лидов',
        id: globalId++,
        navigate: true,
        needToAddConditions: NavItemsEnum.needToAddClientList,
        isShow: false,
        children: [
            {
                href: '/clients-request',
                label: 'Новый лид',
                id: globalId++,
                navigate: true,
                needToAddConditions: NavItemsEnum.needToAddClientList,
                isShow: true,
            },
            {
                href: '/leads',
                label: 'Список лидов',
                id: globalId++,
                navigate: true,
                needToAddConditions: NavItemsEnum.needToAddClientList,
                isShow: true,
            }
        ]
    },
    {
        href: '/about-products',
        label: 'Агентские продукты',
        id: globalId++,
        navigate: true,
        needToAddConditions: NavItemsEnum.needToAddBecomeAgent,
        isShow: false,
    },
    {
        href: '#',
        label: 'Инструменты',
        id: globalId++,
        navigate: true,
        needToAddConditions: NavItemsEnum.needToAddClientList,
        isShow: false,
        children: [
            {
                href: '/about-products',
                label: 'Агентские продукты',
                id: globalId++,
                navigate: true,
                needToAddConditions: NavItemsEnum.needToAddClientList,
                isShow: true,
            },
            {
                href: '/referral-urls',
                label: 'Реферальные ссылки',
                id: globalId++,
                navigate: true,
                needToAddConditions: NavItemsEnum.needToAddClientList,
                isShow: true,
            },
        ]
    },
    {
        href: '/invite-agent',
        label: 'Приведи агента',
        id: globalId++,
        navigate: true,
        needToAddConditions: NavItemsEnum.needToAddInviteAgent,
        isShow: false,
    },
    {
        href: '/partners',
        label: 'Продукты партнёров',
        id: globalId++,
        navigate: true,
        needToAddConditions: NavItemsEnum.default,
        isShow: true,
    },
    {
        href: 'https://support.mts.ru/mts_agents/',
        label: 'Поддержка',
        id: globalId++,
        targetBlank: true,
        target: '_blank',
        needToAddConditions: NavItemsEnum.default,
        isShow: true,
    },
];

export const getNavItems = (...params: NavItemsEnum[]) => {
    return NAV_ITEMS.map((value) =>
        params.includes(value.needToAddConditions) ? { ...value, isShow: true } : value,
    ).filter((value) => value.isShow);
};
